@tailwind base;
@tailwind components;
@tailwind utilities;

.uploadButton {
    @apply py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

.deleteButton {
    @apply py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 disabled:opacity-50 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
}

.estimateButton {
    @apply py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-350 disabled:opacity-50 hover:bg-green-375 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500;
}


.green-button {
    @apply py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 disabled:opacity-50 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500;
}

.standardInput {
    @apply appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm;
}

.standardButton {
    @apply bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded inline-flex items-center
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input + .slider {
    background-color: #3ec3a7;
  }
  
  input:checked + .slider {
    background-color: #6366f1;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 3px #6b7280;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }